import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import ServicesComponent from '../../components/expertise-page/ServicesComponent';
// import ConsultWithUsComponent from '../../components/expertise-page/ConsultWithUsComponent';
import ExpertiseHeroComponent from '../../components/expertise-page/ExpertiseHeroComponent';
import WhyChoosePRIMEComponent from '../../components/expertise-page/WhyChoosePRIMEComponent';
import TrustedByClientsComponent from '../../components/expertise-page/TrustedByClientsComponent';

const StyledDivWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 50px;
  h3 {
    margin-bottom: 0px;
  }

  .com-title {
    border-bottom: 2px solid #003366;
    margin-bottom: 16px;
    span {
      color: ${props => props.theme.primeYellowPalette.original};
      background-color: ${props => props.theme.primeBluePalette.original};
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      font-weight: 700;
      padding: 4px 16px;
      @media only screen and (max-width: 355px) {
        font-size: 0.9rem;
      }
    }
  }

  .strong-description {
    margin: 50px 0px;
    text-align: center;
  }
`;

function OurExpertiseExperiencedPage({ location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiServices(
        filter: { visible: { eq: true }, service_type: { eq: "Experienced" } }
      ) {
        nodes {
          strapiId
          category
          description
          service_client_details
          service_name
          service_type
          strapiId
          tags
          url_slug
          visible
          thumb_photo
          banner_photo
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Our Expertise - Experienced"
        description="Experienced services are optimal for small to medium enterprises who are setting their sights to making a mark in the business landscape. PRIME Philippines is committed to providing real estate solutions fine-tuned to achieve astounding results for our clients. Contact us today to learn more about how to accelerate your business footprint in the market!"
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />

      <StyledDivWrapper>
        <div>
          <ExpertiseHeroComponent serviceStage="experienced" />
          <WhyChoosePRIMEComponent />
          {/* <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <div className="strong-description">
                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque quis justo viverra, elementum sapien et, consequat
                  lorem. Nunc ultricies turpis eget felis iaculis, et pretium
                  velit sagittis.
                </h3>
              </div>
            </Col>
          </Row> */}
          <Row type="flex" justify="center">
            <Col sm={22} xs={22} md={22} lg={22} xl={20} xxl={16}>
              <div className="com-title">
                <span>Our suite of real estate services</span>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col sm={24} xs={24} md={22} lg={22} xl={20} xxl={20}>
              <ServicesComponent services={data.allStrapiServices.nodes} />
            </Col>
          </Row>
          <TrustedByClientsComponent />
        </div>
      </StyledDivWrapper>
    </Layout>
  );
}

OurExpertiseExperiencedPage.propTypes = {
  location: PropTypes.object,
};

export default OurExpertiseExperiencedPage;
