import React, { useState, useRef } from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { Row, Col, Carousel, Tabs } from 'antd';

import DLLMainSubset from '../services/subset/DLLMainSubset';
import DLLAddSubset from '../services/subset/DLLAddSubset';
import ITBPOSubset from '../services/subset/ITBPOSubset';
import GovtSubset from '../services/subset/GovtSubset';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  margin-top: 40px;

  .row-col {
    display: flex;
    justify-content: center;
  }

  .main {
    display: block;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  .row-title {
    color: ${props => props.theme.primeBluePalette.original};
    display: inline-block;
    min-width: 600px;
    font-family: 'Proxima Nova', sans-serif !important;
    margin-top: 16px;
    margin-bottom: 48px;
    text-align: center;
    font-weight: 700;
    padding: 24px;
    padding-bottom: 0px;
    border-bottom: 2px solid ${props => props.theme.primeBluePalette.original};

    :after {
      content: '';
      display: block;
      position: relative;
      top: 19px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 18px 18px 18px 18px;
      margin: 0 auto;
      border-color: ${props => props.theme.primeBluePalette.original};
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }

  .ant-tabs-tab-btn {
    font-weight: 600 !important;
  }
  .ant-carousel {
    width: 100%;
  }
`;

const StyledCarousel = styled(Carousel)`
  margin-top: 20px;
  .developer-logo,
  .client-logo {
    margin-bottom: 0;
  }
`;

const TrustedByClientsComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef();
  return (
    <Wrapper>
      <Row type="flex" justify="center">
        <Col
          className="row-col"
          sm={22}
          xs={22}
          md={22}
          lg={22}
          xl={20}
          xxl={16}
        >
          <h1 className="row-title">TRUSTED BY:</h1>
        </Col>
      </Row>
      <div className="main">
        <Row type="flex" justify="center">
          <Col
            className="row-col"
            sm={22}
            xs={22}
            md={22}
            lg={22}
            xl={20}
            xxl={16}
          >
            <Tabs
              activeKey={`${currentSlide + 1}`}
              onTabClick={key => {
                // console.log(carouselRef);
                carouselRef.current.goTo(+key - 1);
              }}
            >
              <TabPane tab="TOP DEVELOPERS" key="1"></TabPane>
              <TabPane tab="GOVERNMENT INSTITUTIONS" key="2"></TabPane>
              <TabPane tab="IT-BPO LOCATORS" key="3"></TabPane>
            </Tabs>
          </Col>
          <Col
            className="row-col"
            sm={22}
            xs={22}
            md={22}
            lg={22}
            xl={20}
            xxl={16}
          >
            <StyledCarousel
              ref={carouselRef}
              autoplay
              dots={false}
              afterChange={x => setCurrentSlide(x)}
            >
              <div>
                <DLLMainSubset />
                <DLLAddSubset />
              </div>
              <div>
                <GovtSubset />
              </div>
              <div>
                <ITBPOSubset />
              </div>
            </StyledCarousel>
          </Col>
        </Row>
      </div>
      <div className="mobile">
        <Row type="flex" justify="center">
          <Col
            className="row-col"
            sm={22}
            xs={22}
            md={22}
            lg={22}
            xl={20}
            xxl={16}
          >
            <h2>
              <span>Top Developers</span>
            </h2>
          </Col>
        </Row>
        <DLLMainSubset />
        <DLLAddSubset />
        <Row type="flex" justify="center">
          <Col
            className="row-col"
            sm={22}
            xs={22}
            md={22}
            lg={22}
            xl={20}
            xxl={16}
          >
            <h2>
              <span>Government Institutions</span>
            </h2>
          </Col>
        </Row>
        <GovtSubset />
        <Row type="flex" justify="center">
          <Col
            className="row-col"
            sm={22}
            xs={22}
            md={22}
            lg={22}
            xl={20}
            xxl={16}
          >
            <h2>
              <span>IT-BPO Locators</span>
            </h2>
          </Col>
        </Row>
        <ITBPOSubset />
      </div>
    </Wrapper>
  );
};

TrustedByClientsComponent.propTypes = {
  // values: PropTypes.object,
};

export default TrustedByClientsComponent;
