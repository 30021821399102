import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
// import { useStaticQuery, graphql } from 'gatsby';

const StyledDivWrapper = styled.div``;

const StyledColCard = styled(Col)`
  .service-card-wrapper {
    position: relative;
    overflow: hidden;
    :hover {
      opacity: 0.95;
    }

    > div:first-child {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 200px;
      padding: 20px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.48);
      color: white;
      position: relative;
      background-color: ${props =>
        `${props.theme.primeBluePalette.original}CC`};
      z-index: 1;

      .name {
        color: #fad217;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 12px;
        text-transform: uppercase;
      }

      .desc {
        font-size: 0.9em;
        line-height: 1.2em;
      }

      .clientDesc {
        text-align: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        margin-bottom: 0px;
        font-size: 0.7em;
        margin: auto;
        width: 100%;
        background-color: ${props => props.theme.primeBluePalette.original};
        padding: 8px;
        font-style: italic;

        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0px;
          line-height: 1.3;
        }
      }
    }

    ::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: ${props => `url('${props.thumb_photo}')`};
      z-index: 0;
      background-size: cover;
      background-position: center;
    }
  }

  .status {
    position: absolute;
    top: 10px;
    left: 0px;
    background-color: ${props => `${props.theme.primeYellowPalette.original}`};
    padding: 4px 6px;
    font-size: 12px;
    font-weight: bold;
    color: #003366;
  }
`;

function ServicesComponent({ services }) {
  // add useStaticQuery if need to access allStrapiServices

  return (
    <StyledDivWrapper>
      <Row
        type="flex"
        justify="center"
        gutter={[20, 20]}
        style={{ marginBottom: 0, marginTop: 0 }}
      >
        {services.map(service => (
          <StyledColCard
            xs={22}
            sm={22}
            md={12}
            lg={8}
            xl={8}
            xxl={8}
            key={service.service_name}
            thumb_photo={service.thumb_photo}
          >
            <Link
              to={`/our-expertise/${service.service_type.toLowerCase()}/${
                service.url_slug
              }`}
            >
              <div className="service-card-wrapper">
                <div>
                  <span className="name">{service.service_name}</span>

                  {service.description ? (
                    <div className="desc">{service.description}</div>
                  ) : null}

                  {/* {service.service_client_details ? (
                    <div className="clientDesc">
                      <p>{service.service_client_details}</p>
                    </div>
                  ) : null} */}

                  {/* {service.category && (
                    <span className="status">{service.category}</span>
                  )} */}
                </div>
              </div>
            </Link>
          </StyledColCard>
        ))}
      </Row>
    </StyledDivWrapper>
  );
}

ServicesComponent.propTypes = {
  services: PropTypes.array,
};

export default ServicesComponent;
