import React from 'react';
import { Row, Col } from 'antd';

import UdennaLogo from '../../../../static/images/services/developers/udenna.jpg';
import PanoramaLogo from '../../../../static/images/services/developers/panorama.jpg';
import AmberlandLogo from '../../../../static/images/services/developers/amberland.jpg';
import PrimexLogo from '../../../../static/images/services/developers/primex.jpg';

function DLLAddSubset() {
  return (
    <>
      <Row className="first-row" type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="Udenna" src={UdennaLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="Panorama" src={PanoramaLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="Amberland" src={AmberlandLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="PRIMEX" src={PrimexLogo} />
        </Col>
      </Row>
    </>
  );
}

export default DLLAddSubset;
